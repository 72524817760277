import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { NotificationManager } from "react-notifications";
import axios from "axios";
import useAuth from "../hooks/useAuth";
import Loader from "./Loader";
import { BASE_URL } from "../utils/Element";
import BaseLayout from "../layouts/BaseLayout";
import LeftIcon from "../assets/svgs/leftArrowIcon.svg";
import RightIcon from "../assets/svgs/rightArrowIcon.svg";
import CompanyUpdateTab from "../pages/Companies/components/CompanyUpdateTab";
import CompanyContactTab from "../pages/Companies/components/CompanyContactTab";
import PropertyTab from "./DetailTabsData/PropertyTab";
import AcquisitionTab from "./DetailTabsData/AcquisitionTab";
import TaskTab from "./DetailTabsData/TaskTab";
import SmartPlanTab from "./DetailTabsData/SmartPlanTab";
import CompanyDetailHeader from "../pages/Companies/components/CompanyDetailHeader";
import SearchDropdownList from "./SearchDropdownList";
import DetailsHeader from "../pages/Contacts/components/DetailsHeader";
import UpdateTabData from "../pages/Contacts/components/UpdateTabData";
import OffersTabData from "../pages/Contacts/components/OffersTabData";

const FilterData = (contactDetails) => {
  const { id } = useParams();
  const { propertyId } = useParams();
  const [config] = useAuth();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [activeTab, setActiveTab] = useState("updates");
  const [contactAndCompanyDetails, setContactAndCompanyDetails] = useState();
  const [OwnerType, setOwnerType] = useState(0);
  const [refreshData, setRefreshData] = useState(false); // Added to trigger data refresh

  useEffect(() => {
    if (propertyId === "company") {
      setOwnerType(1);
    } else {
      setOwnerType(0);
    }
  }, [propertyId]);

  const fetchContactAndCompanyDetails = (id, propertyId) => {
    setLoading(true);
    const filterData = JSON.parse(localStorage.getItem("filterData")) || {};
    const params = new URLSearchParams({
      ...filterData,
    }).toString();

    const savedQueryParams = localStorage.getItem("queryParams");
    let localQueryParams = {};

    if (savedQueryParams) {
      localQueryParams = Object.fromEntries(new URLSearchParams(savedQueryParams));
    }

    axios
      .post(
        `${BASE_URL}/contact-and-company-detail-getbyid/${id}?owner_type_id=${propertyId}`,
        localQueryParams,
        config
      )
      .then((res) => {
        setContactAndCompanyDetails(res?.data?.data);
      })
      .catch((err) => {
        if (err.response?.data?.message && err.response?.data?.message !== "Too Many Attempts.") {
          NotificationManager.error(err.response?.data?.message);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (id) {
      fetchContactAndCompanyDetails(id, propertyId);
    }
  }, [id, refreshData]); 

  const handleEditSuccess = () => {
    setRefreshData((prev) => !prev); 
  };

  return (
    <BaseLayout>
      <div className="flex justify-between items-center">
        <p
          role="button"
          className="green-H head-5 underline"
          onClick={() => navigate("/properties")}
        >
          Back to Properties
        </p>

        <div className="body-S dark-M flex items-center gap-5">
          {contactAndCompanyDetails?.previous_id !== null && (
            <div
              role="button"
              onClick={() =>
                navigate(
                  `/FilterData/${contactAndCompanyDetails?.previous_id}/${contactAndCompanyDetails?.previous_owner_info_type}`
                )
              }
              className="flex items-center gap-2"
            >
              <img src={LeftIcon} alt="Previous" />
              Previous
            </div>
          )}

          {contactAndCompanyDetails?.next_id !== null && (
            <div
              role="button"
              onClick={() =>
                navigate(
                  `/FilterData/${contactAndCompanyDetails?.next_id}/${contactAndCompanyDetails?.next_owner_info_type}`
                )
              }
              className="flex items-center gap-2"
            >
              Next
              <img src={RightIcon} alt="Next" />
            </div>
          )}
        </div>

        <SearchDropdownList from="company" />
      </div>

      {loading ? (
        <Loader />
      ) : (
        <>
          {contactAndCompanyDetails && OwnerType === 1 ? (
            <>
              <CompanyDetailHeader
                companyDetails={contactAndCompanyDetails}
                onSetCallApiAgain={handleEditSuccess} 
              />
              <div className="contact-details light-bg-L" style={{ paddingLeft: "0px", paddingRight: "0px" }}>
          <div className="flex justify-between header-tabs">
            <p
              role="button"
              onClick={() => setActiveTab("updates")}
              className={`${activeTab === "updates" ? "head-5 dark-H active" : "body-S dark-M"} tab`}
            >
              Updates
            </p>
            <p
              role="button"
              onClick={() => setActiveTab("contacts")}
              className={`${activeTab === "contacts" ? "head-5 dark-H active" : "body-S dark-M"} tab`}
            >
              Contacts
            </p>
            <p
              role="button"
              onClick={() => setActiveTab("properties")}
              className={`${activeTab === "properties" ? "head-5 dark-H active" : "body-S dark-M"} tab`}
            >
              Properties
            </p>
            <p
              role="button"
              onClick={() => setActiveTab("acquisition")}
              className={`${activeTab === "acquisition" ? "head-5 dark-H active" : "body-S dark-M"} tab`}
            >
              Acquisition Criteria
            </p>
            <p
              role="button"
              onClick={() => setActiveTab("tasks")}
              className={`${activeTab === "tasks" ? "head-5 dark-H active" : "body-S dark-M"} tab`}
            >
              Tasks
            </p>
            <p
              role="button"
              onClick={() => setActiveTab("smartPlans")}
              className={`${activeTab === "smartPlans" ? "head-5 dark-H active" : "body-S dark-M"} tab`}
            >
              TouchPlans
            </p>
          </div>

          <div className="p-4">
            {activeTab === "updates" && <CompanyUpdateTab />}
            {activeTab === "contacts" && <CompanyContactTab />}
            {activeTab === "properties" && <PropertyTab from="company" />}
            {activeTab === "acquisition" && <AcquisitionTab from="company" />}
            {activeTab === "tasks" && <TaskTab from="company" />}
            {activeTab === "smartPlans" && <SmartPlanTab from="company" />}
          </div>
        </div>
            </>
          ) : (
            <>
              <DetailsHeader
                contactDetails={contactAndCompanyDetails}
                onSetCallApiAgain={handleEditSuccess} 
              />
               <div className="contact-details light-bg-L" style={{ paddingLeft: "0px", paddingRight: "0px" }}>
          <div className="flex justify-between header-tabs">
            <p
              role="button"
              onClick={() => setActiveTab("updates")}
              className={`${activeTab === "updates" ? "head-5 dark-H active" : "body-S dark-M"} tab`}
            >
              Updates
            </p>
            <p
              role="button"
              onClick={() => setActiveTab("properties")}
              className={`${activeTab === "properties" ? "head-5 dark-H active" : "body-S dark-M"} tab`}
            >
              Properties
            </p>
            <p
              role="button"
              onClick={() => setActiveTab("acquisition")}
              className={`${activeTab === "acquisition" ? "head-5 dark-H active" : "body-S dark-M"} tab`}
            >
              Acquisition Criteria
            </p>
            <p
              role="button"
              onClick={() => setActiveTab("offers")}
              className={`${activeTab === "offers" ? "head-5 dark-H active" : "body-S dark-M"} tab`}
            >
              Offers
            </p>
            <p
              role="button"
              onClick={() => setActiveTab("tasks")}
              className={`${activeTab === "tasks" ? "head-5 dark-H active" : "body-S dark-M"} tab`}
            >
              Tasks
            </p>
            <p
              role="button"
              onClick={() => setActiveTab("smartPlans")}
              className={`${activeTab === "smartPlans" ? "head-5 dark-H active" : "body-S dark-M"} tab`}
            >
              TouchPlans
            </p>
          </div>

          <div className="p-4">
            {activeTab === "updates" && <UpdateTabData />}
            {activeTab === "properties" && <PropertyTab from="contact" />}
            {activeTab === "acquisition" && <AcquisitionTab from="contact" />}
            {activeTab === "offers" && <OffersTabData />}
            {activeTab === "tasks" && <TaskTab from="contact" data={contactAndCompanyDetails} />}
            {activeTab === "smartPlans" && <SmartPlanTab from="contact" />}
          </div>
        </div>
            </>
          )}
        </>
      )}
    </BaseLayout>
  );
};

export default FilterData;
