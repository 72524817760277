import React, { useEffect, useState } from "react";
import { NotificationManager } from "react-notifications";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import useAuth from "../../hooks/useAuth";
import Loader from "../../components/Loader";
import { BASE_URL } from "../../utils/Element";
import badge from "../../assets/icons/badge.svg";
import BaseLayout from "../../layouts/BaseLayout";
import dollar from "../../assets/icons/dollar 2.svg";
import key from "../../assets/icons/key-variant.svg";
import calendar from "../../assets/icons/calendar.svg";
import LeftIcon from "../../assets/svgs/leftArrowIcon.svg";
import percentage from "../../assets/icons/percentage 2.svg";
import RightIcon from "../../assets/svgs/rightArrowIcon.svg";
import BuyersDetailsHeader from "./components/BuyersDetailsHeader";
import SearchDropdownList from "../../components/SearchDropdownList";
import { initialBuyerFilterData } from "../../utils/initialData";

const BuyersDetails = () => {
  const { id } = useParams();
  const [config] = useAuth();
  const location = useLocation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [buyerData, setBuyerData] = useState();
   const [filterData, setFilterData] = useState(() => {
      const localFilterData = localStorage.getItem("filterData");
      return localFilterData ? JSON.parse(localFilterData) : initialBuyerFilterData;
      });

  const fetchDetails = () => {
    setLoading(true);
    const savedQueryParams = localStorage.getItem("queryParams");
    let localQueryParams = {};
  
    if (savedQueryParams) {
      localQueryParams = Object.fromEntries(new URLSearchParams(savedQueryParams));
    }
    axios
      .post(`${BASE_URL}/acquisition-getbyid/${id}`, localQueryParams, config)
      .then((res) => {
        const value = res?.data?.data;
        setBuyerData(value);
      })
      .catch((err) => {
        if (err.response?.data?.message && err.response?.data?.message !== "Too Many Attempts.") {
          NotificationManager.error(err.response?.data?.message);
        }
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    fetchDetails();
  }, [id]);

  const leaseData = [
    {
      icon: key,
      date: buyerData?.min_lease_term_reamaining
        ? `${buyerData?.min_lease_term_reamaining} ${buyerData?.min_lease_term_reamaining > 1 ? "Years" : "Year"} `
        : "0 Year",
      title: "Min Lease Term Remaining"
    },
    {
      icon: dollar,
      date: buyerData?.min_price ? `$ ${buyerData?.min_price?.toLocaleString()}` : "N/A",
      title: "Min Price"
    },
    {
      icon: dollar,
      date: buyerData?.max_price ? `$ ${buyerData?.max_price?.toLocaleString()}` : "N/A",
      title: "Max Price"
    },
    {
      icon: percentage,
      date: buyerData?.min_asking_cap_rate ? `${buyerData?.min_asking_cap_rate} %` : "N/A",
      title: "Asking Cap Rate"
    },
    {
      date: buyerData?.price_sf ? `${buyerData?.price_sf} ` : "N/A",
      title: "Price per SF"
    },
    {
      date: buyerData?.rent_sf ? `${buyerData?.rent_sf} ` : "N/A",
      title: "Rent per SF"
    },
    {
      date: buyerData?.min_demographics && typeof buyerData?.min_demographics === 'object'
        ? Object.keys(buyerData.min_demographics)
            .sort((mileA, mileB) => {
              const order = ['oneMile', 'secondMile', 'thirdMile'];
              return order.indexOf(mileA) - order.indexOf(mileB);
            })
            .map((key, index) => (
              <span key={index}>Mile {index === 0 ? 1 : index === 1 ? 3 : 5}: {buyerData.min_demographics[key]}</span>
            ))
        : buyerData?.min_demographics || "N/A",
      title: "Min. Demographics"
    },
    {
      date: buyerData?.min_traffic_count && typeof buyerData?.min_traffic_count === 'object'
        ? Object.keys(buyerData.min_traffic_count)
            .sort((mileA, mileB) => {
              const order = ['oneMile', 'secondMile', 'thirdMile']; 
              return order.indexOf(mileA) - order.indexOf(mileB);
            })
            .map((key, index) => (
              <span key={index}>Mile {index === 0 ? 1 : index === 1 ? 3 : 5}: {buyerData.min_traffic_count[key]}</span>
            ))
        : buyerData?.min_traffic_count || "N/A",
      title: "Min. Traffic Counts"
    },
    {
      date: buyerData?.total_gla ? `${buyerData?.total_gla} ` : "N/A",
      title: "Total GLA"
    },
  ];
  
  

  const queryParams = new URLSearchParams(location.search);
  const searchValue = queryParams.get('search');

  return (
    <BaseLayout>
      <div className="flex justify-between items-center">
        <p role="button" className="green-H head-5 underline" onClick={() => navigate(`/buyers?search=${searchValue || ''}`)}>
          Back to Buyers                                       
        </p>

        <div className="body-S dark-M flex items-center gap-5">
          {buyerData?.previous_id !== null && (
            <div role="button" onClick={() => navigate(`/buyer/${buyerData?.previous_id}`)} className="flex items-center gap-2">
              <img src={LeftIcon} alt="" />
              Previous Buyer
            </div>
          )}

          {buyerData?.next_id !== null && (
            <div role="button" onClick={() => navigate(`/buyer/${buyerData?.next_id}`)} className="flex items-center gap-2">
              Next Buyer
              <img src={RightIcon} alt="" />
            </div>
          )}
        </div>

        <SearchDropdownList from="buyer" />
      </div>

      <div className="contact-details light-bg-L">
        {loading ? (
          <Loader />
        ) : (
          <>
            <BuyersDetailsHeader buyerData={buyerData} onCallApi={fetchDetails} />

            <div className="flex justify-between mt-4">

                 <div className="w-[50%]">
                     <p className="dark-M body-L body-N">SEARCH CRITERIA</p>
                        <div className="mt-3">
                            {leaseData.slice(0, 1).map((el, idx) => (
                              <div key={idx}>
                                <p className="dark-M body-N">{el.title}</p>
                                <p className="tags green-H body-S body-N">{el.date}</p>
                              </div>
                            ))}
                         </div>
                
                         <div className="mt-3">
                           <p className="dark-M body-N">Availability Status</p>
                             <div className="flex flex-wrap items-center gap-2 mt-1">
                               {buyerData?.availability_status?.map((el, i) => (
                                 <p key={i} className="tags green-H body-S">
                                {el}
                              </p>
                            ))}
                          </div>
                        </div>

                     
                          <div className="mt-3">
                            <p className="dark-M body-N">Property Type</p>
                            <div className="flex flex-wrap items-center gap-2 mt-1">
                              {buyerData?.property_type?.map((el, i) => (
                                <p key={i} className="tags green-H body-S">
                             {el?.type}
                           </p>
                         ))}
                       </div>
                     </div>
                
                  
              
                     <div className="mt-3">
                       <p className="dark-M body-N">Landlord Responsibilities</p>
                       <div className="flex flex-wrap items-center gap-2 mt-1">
                         {buyerData?.landlord_responsibilities?.map((el, i) => (
                           <p key={i} className="tags green-H body-S">
                             {el}
                           </p>
                         ))}
                       </div>
                      </div>
                
 
                 </div>

                <div className="mt-4 m-auto w-[50%]">

               <div className="flex gap-16 mt-3">
                    {leaseData.slice(1, 4).map((el, idx) => (
                      <div key={idx + 1}>
                        <p className="dark-M body-N">{el.title}</p>
                        <p className="tags green-H body-S body-N">{el.date}</p>
                      </div>
                    ))}
                </div>

                <div className="flex mt-3  gap-16">

                  <div className=" mt-3">

                      {leaseData.slice(4, 5).map((el, idx) => (
                          <div key={idx + 5}>
                          <p className="dark-M body-N">{el.title}</p>
                          <p className="tags green-H body-S body-N">{el.date}</p>
                        </div>
                      ))}
                      <div className="mt-6">
                      {leaseData.slice(5, 6).map((el, idx) => (
                           <div key={idx + 6}>
                           <p className="dark-M body-N">{el.title}</p>
                           <p className="tags green-H body-S body-N">{el.date}</p>
                         </div>
                      ))}
                      </div>
                       
                  </div>

                  <div className="flex mt-3 gap-16">
                  {leaseData.slice(6, 8).map((el, idx) => (
                    <div key={idx + 8}>
                      <p className="dark-M body-N">{el.title}</p>
                      <p className="green-H body-S body-N">
                        {el.title === "Min. Traffic Counts" &&
                        buyerData?.min_traffic_count &&
                        typeof buyerData.min_traffic_count === "object" ? (
                          <>
                            <div className="mt-3 mb-2 flex items-center gap-2">
                              <p className="dark-M body-N">1 Mile</p> <span className="tags">{buyerData.min_traffic_count.oneMile || "N/A"}</span> 
                            </div>
                            <div className="mt-3 mb-2 flex items-center gap-2">
                              <p className="dark-M body-N">3 Miles</p> <span className="tags">{buyerData.min_traffic_count.secondMile || "N/A"}</span> 
                            </div>
                            <div className="mt-3 mb-2 flex items-center gap-2">
                              <p className="dark-M body-N">5 Miles</p> <span className="tags">{buyerData.min_traffic_count.thirdMile || "N/A"}</span>
                            </div>
                          </>
                        ) : el.title === "Min. Demographics" &&
                          buyerData?.min_demographics &&
                          typeof buyerData.min_demographics === "object" ? (
                          <>
                            <div className="mt-3 mb-2 flex items-center gap-2">
                            <p className="dark-M body-N">1 Mile</p> <span className="tags">{buyerData.min_demographics.oneMile|| "N/A"}</span> 
                            </div>
                            <div className="mt-3 mb-2 flex items-center gap-2">
                              <p className="dark-M body-N">3 Miles</p> <span className="tags">{buyerData.min_demographics.secondMile || "N/A"}</span> 
                            </div>
                            <div className="mt-3 mb-2 flex items-center gap-2">
                              <p className="dark-M body-N">5 Miles</p> <span className="tags">{buyerData.min_demographics.thirdMile|| "N/A"}</span> 
                            </div>
                          </>
                        ) : (
                          el.date
                        )}
                      </p>
                    </div>
                  ))}
                  </div>

               </div>
              
                  <div className="flex gap-4 mt-3">
                    <div>
                      {leaseData.slice(8, 9).map((el, idx) => (
                          <div key={idx + 9}>
                          <p className="dark-M body-N">{el.title}</p>
                          <p className="tags green-H body-S body-N">{el.date}</p>
                        </div>
                      ))}
                    </div>
                  </div>

               </div>
             </div>
            
             
             <div className="mt-3">
                    <div className="mt-3">
                      <p className="dark-M body-N">Tenants</p>
                         <div className="flex flex-wrap items-center gap-2 mt-1">
                           {buyerData?.tenant_details?.map((el, i) => (
                             <p key={i} className="tags green-H body-S">
                               {el?.tenant_name}
                             </p>
                           ))}
                         </div>
                     </div>

                     
                     <div className="mt-3">
                       <p className="dark-M body-N">State</p>
                       <div className="flex flex-wrap items-center gap-2 mt-1">
                         {buyerData?.state?.map((el, i) => (
                           <p key={i} className="tags green-H body-S">
                             {el}
                           </p>
                         ))}
                       </div>
                     </div>
                 
                     <div className="mt-3 body-N">
                     <p className="dark-M body-N">Comments</p>
                     <p className="dark-H body-N">{buyerData?.comment ?? "N/A"}</p>
                   </div>
             </div>

          </>
        )}
      </div>
    </BaseLayout>
  );
};

export default BuyersDetails;
