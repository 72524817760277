import React, { useState } from "react";
import { NotificationManager } from "react-notifications";
import { useParams } from "react-router-dom";
import axios from "axios";
import Modal from "../../Modal/Modal";
import useAuth from "../../../hooks/useAuth";
import { BASE_URL } from "../../../utils/Element";
import AddAcquisitionData from "../../../pages/Buyers/components/AddAcquisitionData";

const initialData = {
  minPrice: "",
  maxPrice: "",
  minAskingCapRate: "",
  minLeaseTermRemaining: "",
  updateDate: "",
  comment: "",
  contactId: "",
  contactName: "",
  state: [],
  propertyType: [],
  landlordType: [],
  tenantName: [],
  availabilityStatus: [],
  buyerStatus: "",
  min_traffic_count: {
    oneMile: "",
    secondMile: "",
    thirdMile: "",
  },
  min_demographics: {
    oneMile: "",
    secondMile: "",
    thirdMile: "",
  },
  price_sf:"",
  total_gla:"",
  rent_sf:"",
}

const AddAcquisitionModal = ({ showModal, onClose, from, fetchAcquisitions }) => {
  const { id } = useParams();
  const [config] = useAuth();
  const [error, setError] = useState({});
  const [acquisitionData, setAcquisitionsData] = useState(initialData);

  const handleDemographicsChange = (index, value) => {
    setAcquisitionsData((prev) => {
      const updatedDemographics = [...prev.min_demographics];
      updatedDemographics[index] = value;
      return { ...prev, min_demographics: updatedDemographics };
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (Number(acquisitionData?.maxPrice) < Number(acquisitionData?.minPrice)) {
      setError((prev) => ({
        ...prev,
        max_price: "Max price should be greater than min price",
      }));
      return;
    }

    const values = {
      property_type_ids: acquisitionData.propertyType,
      min_price: acquisitionData.minPrice,
      max_price: acquisitionData.maxPrice,
      price_sf: acquisitionData.pricesf,
      rent_sf: acquisitionData.rendsf,
      min_traffic_count: acquisitionData.min_traffic_count,
      min_demographics: acquisitionData.min_demographics, 
      total_gla: acquisitionData.Totalgla,
      min_asking_cap_rate: acquisitionData.minAskingCapRate,
      min_lease_term_reamaining: acquisitionData.minLeaseTermRemaining,
      landlord_responsibilities: acquisitionData.landlordType,
      tenant_name: acquisitionData.tenantName,
      state: acquisitionData.state,
      comment: acquisitionData.comment,
      availability_status: acquisitionData.availabilityStatus,
      buyer_status: acquisitionData.buyerStatus === "Pipeline" ? 0 : acquisitionData.buyerStatus,
    };

    const dataToSend =
      from === "contact"
        ? { ...values, contact_id: id }
        : { ...values, company_id: id, contact_id: acquisitionData.contactId };

    axios
      .post(`${BASE_URL}/add-acquisition`, dataToSend, config)
      .then((res) => {
        if (res?.data?.message) {
          NotificationManager.success(res.data.message);
        }
        handleClose();
        fetchAcquisitions();
      })
      .catch((err) => {
        setError(err.response?.data?.errors || {});
        if (err.response?.data?.message && err.response.data.message !== "Too Many Attempts.") {
          NotificationManager.error(err.response.data.message);
        }
      });
  };

  const handleClose = () => {
    setAcquisitionsData(initialData);
    onClose();
    setError({});
  };

  return (
    <Modal
      title="Add New Acquisition Criteria"
      desc="Add the Acquisition Criteria information."
      show={showModal}
      onClose={handleClose}
    >
      <AddAcquisitionData
        from={from}
        error={error}
        acquisitionData={acquisitionData}
        onSetAcquisitionsData={(value) => setAcquisitionsData((prev) => ({ ...prev, ...value }))}
        onSetError={setError}
        onClose={handleClose}
        handleSubmit={handleSubmit}
        showModal={showModal}
        type="Add"
        renderDemographicsInputs={() =>
          acquisitionData.min_demographics.map((value, index) => (
            <input
              key={index}
              className="body-N"
              type="number"
              min="0"
              placeholder={`${index + 1} Mile`}
              value={value}
              onChange={(e) => handleDemographicsChange(index, e.target.value)}
              onWheel={(e) => e.target.blur()}
            />
          ))
        }
      />
    </Modal>
  );
};

export default AddAcquisitionModal;
