import React, { useEffect, useState } from "react";
import { NotificationManager } from "react-notifications";
import { useParams } from "react-router-dom";
import Select from "react-select";
import axios from "axios";
import useAuth from "../../../hooks/useAuth";
import Loader from "../../../components/Loader";
import { BASE_URL } from "../../../utils/Element";
import percentage from "../../../assets/icons/percentage.svg";
import InputWithIcon from "../../../components/InputWithIcon";
import { landlordOptions, stateOptions } from "../../../utils/options";
import SearchDropdown from "../../../components/Dropdowns/SearchDropdown";
import AddTenantModal from "../../Settings/modals/TenantModals/AddTenantModal";
import MultiSelectDropdown from "../../../components/Dropdowns/MultiSelectDropdown";

const availabilityStatusOptions = [
  { label: "Off Market", value: "Off Market" },
  { label: "On Market", value: "On Market" },
];

const buyerStatusOptions = [{ label: "Pipeline", value: "Pipeline" }];

const AddAcquisitionData = ({ showModal, from, type, error, acquisitionData, onSetAcquisitionsData, handleSubmit, onClose, onSetError }) => {
  const { id } = useParams();
  const [config] = useAuth();
  const [loading, setLoading] = useState(true);
  const [contactOptions, setContactData] = useState([]);
  const [tenantOptions, setTenantOptions] = useState([]);
  const [propertyTypeOptions, setPropertyTypeOptions] = useState([]);
  const [showAddTenantModal, setShowAddTenantModal] = useState(false);
  const [input, setInput] = useState(acquisitionData?.tenantName ?? "");

  const fetchTenants = () => {
    // setLoading(true);
    axios
      .get(`${BASE_URL}/get-datalisting?list=tenant`, config)
      .then((res) => {
        const value = res?.data?.data?.tenant;
        setTenantOptions(value?.map((el) => ({ value: el?.id, label: el?.tenant_name })));
      })
      .catch((err) => {
        if (err.response?.data?.message && err.response?.data?.message !== "Too Many Attempts.") {
          NotificationManager.error(err.response?.data?.message);
        }
      })
      .finally(() => setLoading(false));
  };

  const fetchPropertyTypes = () => {
    setLoading(true);
    axios
      .get(`${BASE_URL}/get-datalisting?list=property_types`, config)
      .then((res) => {
        const value = res?.data?.data?.property_types;
        setPropertyTypeOptions(value?.map((el) => ({ value: el?.id, label: el?.type })));
      })
      .catch((err) => {
        if (err.response?.data?.message && err.response?.data?.message !== "Too Many Attempts.") {
          NotificationManager.error(err.response?.data?.message);
        }
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    if (showModal) {
      fetchPropertyTypes();
      fetchTenants();
    }
  }, [showModal]);

  useEffect(() => {
    if (acquisitionData?.tenantName !== "") {
      setInput(acquisitionData?.tenantName);
    } else {
      setInput("");
    }
  }, [acquisitionData?.tenantName]);

  const fetchContactSuggestions = (inputValue) => {
    if (inputValue !== "") {
      const url = from === "company" ? `get-datalisting?list=contacts&company_id=${id}&search=${inputValue}` : `get-datalisting?list=contacts&search=${inputValue}`;
      axios
        .get(`${BASE_URL}/${url}`, config)
        .then((res) => {
          const value = res?.data?.data?.contact_list;
          setContactData(
            value?.map((data) => ({
              value: data?.id,
              label: `${data?.first_name} ${data?.last_name}`,
            }))
          );
        })
        .catch((err) => {
          if (err.response?.data?.message && err.response?.data?.message !== "Too Many Attempts.") {
            NotificationManager.error(err.response?.data?.message);
          }
        });
    } else {
      setContactData([]);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    onSetAcquisitionsData({ ...acquisitionData, [name]: value });
  };

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <form className="py-3" onSubmit={handleSubmit}>
          <div className="mb-6">
            <p className="head-4 dark-H mb-2">
              Availability Status
            </p>
            <Select
              isSearchable
              isMulti
              className="service-area body-N"
              options={availabilityStatusOptions}
              placeholder="Select Availability Status"
              value={availabilityStatusOptions?.filter((el) => acquisitionData?.availabilityStatus?.includes(el.value))}
              onChange={(options) => {
                const values = options?.map((el) => el?.value);
                onSetError({ ...error, availability_status: "" });
                onSetAcquisitionsData({ ...acquisitionData, availabilityStatus: values });
              }}
            />
            {error?.availability_status && <span className="body-S red-D">{error?.availability_status}</span>}
          </div>


          {from !== "contact" && (
            <div className="mb-6">
              <p className="head-4 dark-H mb-2">
                Contact Name<span className="red-D">*</span>
              </p>
              <SearchDropdown
                isTop={false}
                placeholder="Search Here..."
                text={acquisitionData?.contactName}
                options={contactOptions}
                fetchSuggestions={fetchContactSuggestions}
                onSetOptions={(value) => setContactData(value)}
                handleSelect={(option) => {
                  onSetError({ ...error, contact_id: "" });
                  onSetAcquisitionsData({ ...acquisitionData, contactId: option.value, contactName: option.label });
                  setContactData([]);
                }}
              />
              {error?.contact_id && <span className="body-S red-D">{error?.contact_id}</span>}
            </div>
          )}

          <MultiSelectDropdown
            label="Property Type"
            placeholder="Select Property Type"
            options={propertyTypeOptions}
            selectOptions={acquisitionData?.propertyType}
            onSetSelectOptions={(value) => {
              onSetError({ ...error, property_type_ids: "" });
              onSetAcquisitionsData({ ...acquisitionData, propertyType: value });
            }}
          />
          {error?.property_type_ids && <span className="body-S red-D">{error?.property_type_ids}</span>}

          <div className="mt-6">
            <label className="dark-H head-4 mb-2">
              Price Range
            </label>
            <div className="flex">
              <input
                className="body-N"
                type="number"
                min="0"
                placeholder="Min Price"
                name="minPrice"
                value={acquisitionData.minPrice}
                onChange={(e) => {
                  onSetError({ ...error, min_price: "" });
                  handleChange(e);
                }}
                onWheel={(e) => e.target.blur()}
              />
              <input
                className="body-N"
                type="number"
                min="0"
                placeholder="Max Price"
                name="maxPrice"
                value={acquisitionData.maxPrice}
                onChange={(e) => {
                  onSetError({ ...error, max_price: "" });
                  handleChange(e);
                }}
                onWheel={(e) => e.target.blur()}
              />
            </div>
            <div className="flex justify-between">
              {error?.min_price && <span className="body-S red-D">{error?.min_price}</span>}

              {error?.max_price && <span className="body-S red-D">{error?.max_price}</span>}
            </div>
          </div>

          <div className="mt-6">
            <div className="flex">
              <div>
              <label className="dark-H head-4 mb-2">
                Price per SF
              </label>
              <input
                className="body-N"
                type="number"
                min="0"
                placeholder="Price per SF"
                name="price_sf"
                value={acquisitionData.price_sf}
                onChange={(e) => {
                  onSetError({ ...error, price_sf: "" });
                  handleChange(e);
                }}
                onWheel={(e) => e.target.blur()}
              />
              </div>

             <div>
               <label className="dark-H head-4 mb-2">
               Rent per SF
              </label>
             <input
                className="body-N"
                type="number"
                min="0"
                placeholder="Rent per SF"
                name="rent_sf"
                value={acquisitionData.rent_sf}
                onChange={(e) => {
                  onSetError({ ...error,  rent_sf: "" });
                  handleChange(e);
                }}
                onWheel={(e) => e.target.blur()}
              />
            </div>
              
            </div>
            <div className="flex justify-between">
              {error?.price_sf && <span className="body-S red-D">{error?.price_sf}</span>}

              {error?. rent_sf && <span className="body-S red-D">{error?. rent_sf}</span>}
            </div>
          </div>

          <div className="mt-6">
            <div className="flex">
                 <div>
                  <label className="dark-H head-4 mb-2">Min. Demographics</label>
                  <input
                    className="body-N"
                    type="number"
                    min="0"
                    placeholder="1 Mile"
                    name="oneMile"
                    value={acquisitionData.min_demographics?.oneMile || ""}
                    onChange={(e) => {
                      onSetError({ ...error, min_demographics: "" });
                      handleChange({
                        target: {
                          name: "min_demographics",
                          value: { ...acquisitionData.min_demographics, oneMile: e.target.value },
                        },
                      });
                    }}
                    onWheel={(e) => e.target.blur()}
                  />
                  <input
                    className="body-N"
                    type="number"
                    min="0"
                    placeholder="3 Miles"
                    name="secondMile"
                    value={acquisitionData.min_demographics?.secondMile || ""}
                    onChange={(e) => {
                      onSetError({ ...error, min_demographics: "" });
                      handleChange({
                        target: {
                          name: "min_demographics",
                          value: { ...acquisitionData.min_demographics, secondMile: e.target.value },
                        },
                      });
                    }}
                    onWheel={(e) => e.target.blur()}
                  />
                  <input
                    className="body-N"
                    type="number"
                    min="0"
                    placeholder="5 Miles"
                    name="thirdMile"
                    value={acquisitionData.min_demographics?.thirdMile || ""}
                    onChange={(e) => {
                      onSetError({ ...error, min_demographics: "" });
                      handleChange({
                        target: {
                          name: "min_demographics",
                          value: { ...acquisitionData.min_demographics, thirdMile: e.target.value },
                        },
                      });
                    }}
                    onWheel={(e) => e.target.blur()}
                  />
                </div>

                <div>
              <label className="dark-H head-4 mb-2">Min. Traffic Counts</label>
            
              <input
                className="body-N"
                type="number"
                min="0"
                placeholder="1 Mile"
                name="min_traffic_count"
                value={acquisitionData.min_traffic_count?.oneMile || ""}
                onChange={(e) => {
                  onSetError({ ...error, min_traffic_count: "" }); // Clear error
                  handleChange({
                    target: {
                      name: "min_traffic_count",
                      value: { ...acquisitionData.min_traffic_count, oneMile: e.target.value },
                    },
                  });
                }}
                onWheel={(e) => e.target.blur()}
              />
            
              <input
                className="body-N"
                type="number"
                min="0"
                placeholder="3 Miles"
                name="min_traffic_count"
                value={acquisitionData.min_traffic_count?.secondMile || ""}
                onChange={(e) => {
                  onSetError({ ...error, min_traffic_count: "" }); // Clear error
                  handleChange({
                    target: {
                      name: "min_traffic_count",
                      value: { ...acquisitionData.min_traffic_count, secondMile: e.target.value },
                    },
                  });
                }}
                onWheel={(e) => e.target.blur()}
              />
            
              <input
                className="body-N"
                type="number"
                min="0"
                placeholder="5 Miles"
                name="min_traffic_count"
                value={acquisitionData.min_traffic_count?.thirdMile || ""}
                onChange={(e) => {
                  onSetError({ ...error, min_traffic_count: "" }); // Clear error
                  handleChange({
                    target: {
                      name: "min_traffic_count",
                      value: { ...acquisitionData.min_traffic_count, thirdMile: e.target.value },
                    },
                  });
                }}
                onWheel={(e) => e.target.blur()}
              />
            </div>

              
            </div>
            <div className="flex justify-between">
              {error?.min_demographics && <span className="body-S red-D">{error?.min_demographics}</span>}

              {error?. min_traffic_count && <span className="body-S red-D">{error?. min_traffic_count}</span>}
            </div>
          </div>

          <div className="mt-6">
             <div>
              <label className="dark-H head-4 mb-2">
                Total GLA
              </label>
              <input
                className="body-N"
                type="number"
                min="0"
                placeholder=" Total Gla"
                name="total_gla"
                value={acquisitionData.total_gla}
                onChange={(e) => {
                  onSetError({ ...error, total_gla: "" });
                  handleChange(e);
                }}
                onWheel={(e) => e.target.blur()}
              />
            </div>
                {error?.total_gla && <span className="body-S red-D">{error?.total_gla}</span>}
            </div>

          <div className="mt-6 ">
            <label className="dark-H head-4 mb-2">
              Minimum Asking Cap Rate
            </label>
            <InputWithIcon
              icon={percentage}
              type="number"
              min="0"
              placeholder="Min Asking Cap Rate"
              name="minAskingCapRate"
              value={acquisitionData.minAskingCapRate}
              onChange={(e) => {
                const value = e.target.value;
                var regex = /^\d*\.?\d{0,2}$/;
                if (regex.test(value) || value === "") {
                  if (value > 100) {
                    return;
                  } else {
                    onSetError({ ...error, min_asking_cap_rate: "" });
                    onSetAcquisitionsData({ ...acquisitionData, minAskingCapRate: value });
                  }
                }
              }}
              onWheel={(e) => e.target.blur()}
            />
            {error?.min_asking_cap_rate && <span className="body-S red-D">{error?.min_asking_cap_rate}</span>}
          </div>

          <div className="mt-6">
            <label className="dark-H head-4 mb-2">
              Minimum Lease Term Remaining <span className="body-S">(in years)</span>
            </label>
            <input
              className="body-N"
              type="number"
              step="0.01"
              placeholder="Min Lease Term Remaining"
              name="minLeaseTermRemaining"
              value={acquisitionData.minLeaseTermRemaining}
              onChange={(e) => {
                const value = e.target.value;
                var regex = /^\d*\.?\d{0,2}$/;
                if (regex.test(value) || value === "") {
                  onSetError({ ...error, min_lease_term_reamaining: "" });
                  onSetAcquisitionsData({ ...acquisitionData, minLeaseTermRemaining: value });
                }
              }}
              onWheel={(e) => e.target.blur()}
            />
            {error?.min_lease_term_reamaining && <span className="body-S red-D">{error?.min_lease_term_reamaining}</span>}
          </div>

          <div className="mt-6">
            <MultiSelectDropdown
              label="Landlord Responsibility"
              placeholder="Select Landlord Responsibilities"
              options={landlordOptions}
              selectOptions={acquisitionData?.landlordType}
              onSetSelectOptions={(value) => {
                onSetError({ ...error, landlord_responsibilities: "" });
                onSetAcquisitionsData({ ...acquisitionData, landlordType: value });
              }}
            />
            {error?.landlord_responsibilities && <span className="body-S red-D">{error?.landlord_responsibilities}</span>}
          </div>

          <div className="mt-6">
            <div className="flex justify-between items-center">
              <label className="dark-H head-4">Tenant Name
              </label>
              <button
                type="button"
                className="green-H body-N underline"
                onClick={() => {
                  setShowAddTenantModal(true);
                }}
              >
                +Add New Tenant
              </button>
            </div>

            <div className="mt-2">
              <Select
                isMulti
                className="service-area"
                placeholder="Select Tenant"
                options={tenantOptions}
                value={tenantOptions?.filter((el) => acquisitionData?.tenantName?.includes(el.value))}
                onChange={(options) => {
                  const values = options?.map((el) => el?.value);
                  onSetError({ ...error, tenant_name: "" });
                  onSetAcquisitionsData({ ...acquisitionData, tenantName: values });
                }}
              />
            </div>
            {error?.tenant_name && <span className="body-S red-D">{error?.tenant_name}</span>}
          </div>

          <div className="mt-6">
            <p className="head-4 dark-H mb-2 ">
              State
            </p>

            <Select
              isMulti
              className="service-area"
              placeholder="Select State"
              options={stateOptions}
              value={stateOptions?.filter((el) => acquisitionData?.state?.includes(el.value))}
              onChange={(options) => {
                const values = options?.map((el) => el?.value);
                onSetError({ ...error, state: "" });
                if (values?.[values?.length - 1] === "Nationwide") {
                  onSetAcquisitionsData({ ...acquisitionData, state: ["Nationwide"] });
                } else {
                  onSetAcquisitionsData({ ...acquisitionData, state: values?.filter((el) => el !== "Nationwide") });
                }
              }}
            />
            {error?.state && <span className="body-S red-D">{error?.state}</span>}
          </div>

          <div className="flex-1 mt-6">
            <p className="head-4 dark-H">
              Comments <span className="body-S dar-M">(optional)</span>
            </p>
            <textarea
              rows={4}
              placeholder="Write Contact Description"
              className="mt-2 w-full body-N"
              name="comment"
              value={acquisitionData.comment}
              onChange={(e) => {
                onSetError({ ...error, comment: "" });
                handleChange(e);
              }}
            />
          </div>

          <div className="mt-6">
            <button type="submit" className="save-button light-L head-5 green-bg-H">
              {type} Acquisition Criteria
            </button>
            <button type="button" onClick={onClose} className="green-H ml-5">
              Cancel
            </button>
          </div>
        </form>
      )}

      <AddTenantModal showModal={showAddTenantModal} onClose={() => setShowAddTenantModal(false)} onCallApi={fetchTenants} />
    </>
  );
};

export default AddAcquisitionData;
