import React, { useEffect, useState } from "react";
import { Radio, RadioGroup, Stack } from "@chakra-ui/react";
import { NotificationManager } from "react-notifications";
import DatePicker from "react-datepicker";
import Dropdown from "react-dropdown";
import Select from "react-select";
import axios from "axios";
import useAuth from "../../../hooks/useAuth";
import { BASE_URL } from "../../../utils/Element";
import dollor from "../../../assets/icons/dollar.svg";
import { landlordOptions } from "../../../utils/options";
import percentage from "../../../assets/icons/percentage.svg";
import InputWithIcon from "../../../components/InputWithIcon";
import { useParams } from "react-router-dom";

const AddPropertyModalData = ({ from, error, setError,  propertyInfo, handleSubmit, handleClose, onSetFormData, clearError ,isTop = false }) => {
  const [config] = useAuth();
  const [ownerOptions, setOwnerOptions] = useState([]);
  const [propertyTagOptions, setPropertyTagOptions] = useState([]);
  const [propertyTypeOptions, setPropertyTypeOptions] = useState([]);
  const [options, setOptions] = useState([]);
  const [searchList, setSearchList] = useState([]);
  const [propertyId, setPropertyId] = useState(null);


   const handleChange = (value, fieldName) => {

    if (["zipcode"].includes(fieldName)) {
      value = value.replace(/[^\d+\-.,]/g, "");
      onSetFormData((prevState) => ({
        ...prevState,
        [fieldName]: value,
      }));
    } else if (["askingCapRate", "annualRent", "askingPrice", "buildingSize", "landSize"].includes(fieldName)) {
      var regex = /^\d*\.?\d{0,2}$/;
      if (regex.test(value) || value === "") {
        onSetFormData((prevState) => ({
          ...prevState,
          [fieldName]: value,
        }));
      }
    }if (["lastUpdateDate", "leaseCommencementDate", "leaseExpirationDate"].includes(fieldName)) {
      onSetFormData((prevState) => ({
        ...prevState,
        [fieldName]: value ? new Date(value) : "", 
      }));
    }
    else {
      onSetFormData((prevState) => ({
        ...prevState,
        [fieldName]: value,
      }));
    }
  };

  const fetchPropertyTypes = () => {
    axios
      .get(`${BASE_URL}/get-datalisting?list=property_types`, config)
      .then((res) => {
        const value = res?.data?.data?.property_types;
        setPropertyTypeOptions(value?.map((el) => ({ value: el?.id, label: el?.type })));
      })
      .catch((err) => {
        if (err.response?.data?.message && err.response?.data?.message !== "Too Many Attempts.") {
          NotificationManager.error(err.response?.data?.message);
        }
      });
  };

  const fetchPropertyTags = () => {
    axios
      .get(`${BASE_URL}/get-datalisting?list=property_tag`, config)
      .then((res) => {
        const value = res?.data?.data?.property_tag;
        setPropertyTagOptions(value?.map((el) => ({ value: el?.id, label: el?.tag_name })));
      })
      .catch((err) => {
        if (err.response?.data?.message && err.response?.data?.message !== "Too Many Attempts.") {
          NotificationManager.error(err.response?.data?.message);
        }
      });
  };

  useEffect(() => {
    fetchPropertyTypes();
    fetchPropertyTags();
  }, []);


  
 
  const fetchSuggestions = (inputValue) => {
    if (inputValue.trim() === "") {
      setOptions([]);
      return;
    }
    const encodedInputValue = encodeURIComponent(inputValue);
    axios
      .get(`${BASE_URL}/search?search=${encodedInputValue}&type=property`, config)
      .then((res) => {
        const data = res.data?.results;
        console.log('Fetching data for:', data);
        const propertyOptions = data?.map((el) => ({
          value: el?.id,
          label: el?.property_name,
          desc: `${el.street_address}, ${el.city}, ${el.state}`
        }));
        console.log('Fetched options:', propertyOptions);
        setOptions(propertyOptions);
      })
      .catch((err) => {
        console.error('Error fetching suggestions:', err);
        if (err.response?.data?.message && err.response?.data?.message !== "Too Many Attempts.") {
          setError((prev) => ({ ...prev, property_name: err.response?.data?.message }));
        }
      });
  };


  const handleSelectProperty = (option) => {
    const id = option.value;
    onSetFormData((prevState) => ({
      ...prevState,
      ['property_id']: option.value,
    }));
    axios
      .get(`${BASE_URL}/property-getbyid/${id}`, config)
      .then((res) => {
        const propertyData = res.data?.data;
        console.log('property-getbyid data:', propertyData);
        
        onSetFormData((prevState) => ({
          ...prevState,
          ...propertyData,
          propertyName: propertyData.property_name,
          streetAddress: propertyData.street_address,
          buildingSize: propertyData.building_size,
          propertyType: propertyData.property_type?.id,
          leaseType: propertyData.lease_type?.id,
          }));
      })
      .catch((err) => {
        if (err.response?.data?.message && err.response?.data?.message !== "Too Many Attempts.") {
          NotificationManager.error(err.response?.data?.message);
        }
      });

    setOptions([]);
  };

 

  useEffect(() => {
    if (propertyInfo.annualRent !== "" && propertyInfo.askingCapRate !== "") {
      handleChange(propertyInfo.annualRent, "annualRent");
      handleChange(propertyInfo.askingCapRate, "askingCapRate");
    }
  }, [propertyInfo.annualRent, propertyInfo.askingCapRate]);


  return (
    <form className="contact-details light-bg-L h-screen overflow-y-auto hide-scrollbar" onSubmit={handleSubmit}>
      <div className="md:flex gap-5 mt-6">
      <div className="w-[100%] relative">
                <label className="dark-H head-4 mb-2">
                  Property Name<span className="red-D">*</span>
                </label>
                
                <input
                  className="body-N"
                  name="propertyName"
                  type="text"
                  autocomplete="off"
                  placeholder="Write property name here"
                  value={propertyInfo?.propertyName}
                  onChange={(e) => {
                    handleChange(e.target.value, "propertyName"); 
                    fetchSuggestions(e.target.value); 
                  }}
                />
                
                {error?.property_name && (
                <p className="body-S red-D pt-1">{error.property_name}</p> 
              )}

                {options.length > 0 && (
                 <div className={`absolute ${isTop ? "bottom-10" : ""} z-10 w-full mt-1 light-bg-L border border-gray-300 rounded-md shadow-md max-h-[40vh] overflow-y-auto`}>
                   {options.map((option) => (
                     <div
                       key={option.value}
                       className="px-3 py-2 body-N hover:bg-gray-100 dark-H"
                       onClick={() => handleSelectProperty(option)}
                     >
                       <p>{option.label}</p>
                       <p className="body-S dark-M">{option.desc}</p>
                     </div>
                   ))}
                 </div>
               )}
      </div>

        <div className="w-[100%] mt-6 md:mt-0">
          <label className="dark-H head-4 mb-2">Store #</label>
          <input
            className="body-N"
            type="text"
            name="storeNumber"
            placeholder="Write store number"
            value={propertyInfo.storeNumber}
            onChange={(e) => {
              clearError("store");
              handleChange(e.target.value, "storeNumber");
            }}
          />
          {error?.store && <p className="body-S red-D">{error?.store}</p>}
        </div>

        <div className="w-[100%] mt-6 md:mt-0">
          <label className="dark-H head-4">
            Property Type<span className="red-D">*</span>
          </label>
          <div className="mt-1.5">
            <Select
              className="body-N"
              options={propertyTypeOptions}
              placeholder={"Select property type"}
              value={propertyTypeOptions.find((option) => option.value === propertyInfo.propertyType)}
              onChange={(option) => {
                clearError("property_type");
                handleChange(option.value, "propertyType");
              }}
            />
          </div>
          {error?.property_type && <p className="body-S red-D">{error?.property_type}</p>}
        </div>
      </div>

      <div className="md:flex gap-5 mt-6">
        <div className="w-[100%]">
          <label className="dark-H head-4 mb-2">
            Street Address
          </label>
          <input
            className="body-N"
            name="streetAddress"
            type="text"
            placeholder="Street Address"
            value={propertyInfo.streetAddress}
            onChange={(e) => {
              clearError("street_address");
              handleChange(e.target.value, "streetAddress");
            }}
          />
          {error?.street_address && <p className="body-S red-D">{error?.street_address}</p>}
        </div>

        <div className="w-[65%] mt-6 md:mt-0">
          <label className="dark-H head-4 mb-2">
            City<span className="red-D">*</span>
          </label>
          <input
            className="body-N"
            type="text"
            placeholder="City"
            value={propertyInfo.city}
            onChange={(e) => {
              clearError("city");
              handleChange(e.target.value, "city");
            }}
          />
          {error?.city && <p className="body-S red-D pt-1">{error?.city}</p>}
        </div>

        <div className="w-[65%] mt-6 md:mt-0">
          <label className="dark-H head-4 mb-2">
            State<span className="red-D">*</span>
          </label>
          <input
            className="body-N"
            name="state"
            type="text"
            placeholder="State"
            value={propertyInfo.state}
            onChange={(e) => {
              clearError("state");
              handleChange(e.target.value, "state");
            }}
          />
          {error?.state && <p className="body-S red-D pt-1">{error?.state}</p>}
        </div>

        <div className="w-[65%] mt-6 md:mt-0">
          <label className="dark-H head-4 mb-2">
            Zipcode
          </label>
          <input
            className="body-N"
            name="zipcode"
            type="text"
            placeholder="Zipcode"
            value={propertyInfo.zipcode}
            maxLength={13}
            onChange={(e) => {
              clearError("zipcode");
              handleChange(e.target.value, "zipcode");
            }}
          />
          {error?.zipcode && <p className="body-S red-D pt-1">{error?.zipcode}</p>}
        </div>
      </div>

      <div className="md:flex gap-5 mt-6">
        <div className="w-[100%] mt-6 md:mt-0">
          <label className="dark-H head-4">Property Tag</label>
          <div className="mt-1.5">
            <Select
              className="body-N service-area"
              isMulti
              options={propertyTagOptions}
              placeholder={"Select property tag"}
              value={propertyTagOptions?.filter((el) => Array.isArray(propertyInfo.propertyTag) && propertyInfo.propertyTag.includes(el?.value))}

              onChange={(options) => {
                clearError("propertytag_id");
                const values = options?.map((el) => el.value);
                handleChange(values, "propertyTag");
              }}
            />
          </div>
          {error?.propertytag_id && <p className="body-S red-D">{error?.propertytag_id}</p>}
        </div>

        <div className="w-[100%]">
          <label className="dark-H head-4 mb-2">Google Maps Link</label>
          <input
            className="body-N"
            git
            name="googleMapsLink"
            type="text"
            placeholder="Link to property in Google Maps"
            value={propertyInfo.googleMapsLink}
            onChange={(e) => {
              clearError("google_map_link");
              handleChange(e.target.value, "googleMapsLink");
            }}
          />
          {error?.google_map_link && <p className="body-S red-D">{error?.google_map_link}</p>}
        </div>

        <div className="w-[100%] mt-6 md:mt-0">
          {/* <label className="dark-H head-4 mb-2">RPR Link</label>
          <input
            className="body-N"
            name="rprLink"
            type="text"
            placeholder="Write property RPR link"
            value={propertyInfo.rprLink}
            onChange={(e) => {
              clearError("rpr_link");
              handleChange(e.target.value, "rprLink");
            }}
          /> */}
          {error?.rpr_link && <p className="body-S red-D">{error?.rpr_link}</p>}
        </div>
      </div>

      <div className="md:flex gap-5 mt-6">
        <div className="mt-6 md:mt-0" style={{ width: "100%" }}>
          <label className="dark-H head-4 mb-2">Comments</label>
          <textarea
            rows={5}
            className="body-N"
            name="comment"
            type="text"
            placeholder="Write comments here..."
            value={propertyInfo.comment}
            onChange={(e) => {
              clearError("comment");
              handleChange(e.target.value, "comment");
            }}
          />
          {error?.comment && <p className="body-S red-D">{error?.comment}</p>}
        </div>
      </div>

      <hr className="my-8" />

      <p className="dark-M body-L">LEASE INFORMATION</p>

      <div className="mt-6 md:flex gap-5">
        <div className="md:w-[50%]">
          <p className="head-4 dark-H mb-2">Lease Type</p>
          <Dropdown
            className="company-select body-N"
            options={landlordOptions}
            placeholder="Select Option"
            value={landlordOptions.find((option) => option.value === propertyInfo.leaseType)}
            onChange={(option) => {
              clearError("lease_type");
              handleChange(option.value, "leaseType");
            }}
          />
          {error?.lease_type && <p className="body-S red-D">{error?.lease_type}</p>}
        </div>

        <div className="mt-6 md:mt-0">
          <label className="dark-H head-4 mb-2">Lease Commencement Date</label>
          <DatePicker
            className="body-N"
            dateFormat="MM/dd/yyyy"
            placeholderText="MM/DD/YYYY"
            // maxDate={new Date()}
            selected={propertyInfo.leaseCommencementDate}
            onChange={(date) => {
              clearError("lease_commencement_date");
              handleChange(date, "leaseCommencementDate");
            }}
          />
          {error?.lease_commencement_date && <p className="body-S red-D">{error?.lease_commencement_date}</p>}
        </div>

        <div className="mt-6 md:mt-0">
          <label className="dark-H head-4 mb-2">Lease Expiration Date</label>
          <DatePicker
            className="body-N"
            dateFormat="MM/dd/yyyy"
            placeholderText="MM/DD/YYYY"
            // maxDate={new Date()}
            minDate={propertyInfo.leaseCommencementDate}
            selected={propertyInfo.leaseExpirationDate}
            onChange={(date) => {
              clearError("lease_expiration_date");
              handleChange(date, "leaseExpirationDate");
            }}
          />
          {error?.lease_expiration_date && <p className="body-S red-D">{error?.lease_expiration_date}</p>}
        </div>
      </div>

      <div className="mt-6 md:flex gap-5">
        <div className="w-[100%]">
          <label className="dark-H head-4 mb-2">Annual Rent/NOI</label>
          <InputWithIcon
            icon={dollor}
            type="number"
            placeholder="Annual rent"
            value={propertyInfo.annualRent}
            onChange={(e) => {
              clearError("anual_rent");
              handleChange(e.target.value, "annualRent");
            }}
          />
          {error?.anual_rent && <p className="body-S red-D">{error?.anual_rent}</p>}
        </div>

        <div className="w-[100%] mt-6 md:mt-0">
          <label className="dark-H head-4 mb-2">Asking Cap Rate</label>
          <InputWithIcon
            icon={percentage}
            type="number"
            placeholder="Asking cap rate"
            value={propertyInfo.askingCapRate}
            onChange={(e) => {
              var value = e.target.value;
              var regex = /^\d*\.?\d{0,2}$/;
              if (regex.test(value) || value === "") {
                clearError("asking_cap_rate");
                if (value > 100) {
                  return;
                } else {
                  handleChange(value, "askingCapRate");
                }
              }
            }}
          />
          {error?.asking_cap_rate && <p className="body-S red-D">{error?.asking_cap_rate}</p>}
        </div>

        <div className="w-[100%] mt-6 md:mt-0">
          <label className="dark-H head-4 mb-2">Asking Price</label>
          <InputWithIcon
            icon={dollor}
            type="number"
            placeholder="Asking price"
            value={propertyInfo.askingPrice}
            onChange={(e) => {
              clearError("asking_price");
              handleChange(e.target.value, "askingPrice");
            }}
          />
          {error?.asking_price && <p className="body-S red-D">{error?.asking_price}</p>}
        </div>
      </div>

      <div className="mt-6 md:flex gap-5">
        <div className="mt-6 md:mt-0">
          <label className="dark-H head-4 mb-2">Building Size</label>
          <div className="flex password-field items-center">
            <input
              className="body-N"
              value={propertyInfo.buildingSize}
              min={0}
              step="0.01"
              type="number"
              placeholder="Building size"
              onChange={(e) => {
                clearError("building_size");
                handleChange(e.target.value, "buildingSize");
              }}
              onWheel={(e) => {
                e.target.blur();
              }}
            />
            <p className="body-N dark-M">sqft</p>
          </div>
          {error?.building_size && <p className="body-S red-D">{error?.building_size}</p>}
        </div>

        <div className="mt-6 md:mt-0">
          <label className="dark-H head-4 mb-2">Land Size</label>
          <div className="flex password-field items-center">
            <input
              className="body-N"
              value={propertyInfo.landSize}
              min={0}
              step="0.01"
              type="number"
              placeholder="Land Size"
              onChange={(e) => {
                clearError("landSize");
                handleChange(e.target.value, "landSize");
              }}
              onWheel={(e) => {
                e.target.blur();
              }}
            />
            <p className="body-N dark-M">acres</p>
          </div>
          {error?.land_size && <p className="body-S red-D">{error?.land_size}</p>}
        </div>

        <div className="mt-6 md:mt-0">
           <label className="dark-H head-4 mb-2">Last Update Date</label>
           <DatePicker
            className="body-N"
            dateFormat="MM/dd/yyyy"
            placeholderText="MM/DD/YYYY"
            selected={propertyInfo.lastUpdateDate ? new Date(propertyInfo.lastUpdateDate) : null}
            onChange={(date) => {
              handleChange(date, "lastUpdateDate");
            }}
/>
           {error?.last_updated_date && <p className="body-S red-D">{error?.last_updated_date}</p>}
       </div>

      </div>

      <div className="mt-6 md:flex gap-5">
          <div className="mt-6 md:mt-0">
            <label className="dark-H head-4 mb-2">
              Year Built
            </label>
            <input
             className="body-N"
              type="number"
              min="0"
              placeholder="Write property year built"
              value={propertyInfo?.year_built?.split("-")[0] || ""} 
              onChange={(e) => {
                const year = e.target.value;
                handleChange(`${year}`, "year_built"); 
                clearError("year_built");
              }}
              onWheel={(e) => e.target.blur()}
            />
          {error?.year_built && <p className="body-S red-D">{error.year_built}</p>}
          </div>
    
         <div className="mt-6 md:mt-0">
            <label className="dark-H head-4 mb-2">
              Year Renovated
            </label>
            <input
            className="body-N"
              type="number"
              min="0"
              placeholder="Write property year renovated"
              value={propertyInfo?.year_renovated?.split("-")[0] || ""} 
              onChange={(e) => {
                const year = e.target.value;
                handleChange(`${year}`, "year_renovated"); 
              }}
              onWheel={(e) => e.target.blur()}
            />
              {error?.year_renovated && <p className="body-S red-D">{error.year_renovated}</p>}
          </div>
        
          <div className="mt-6 md:mt-0">
           <label className="dark-H head-4 mb-2">
             Guarantor Options
           </label>
           <input
           className="body-N"
             type="text"
             placeholder="Write Guarantor Options"
             value={propertyInfo?.guarantor_options || ""} 
             onChange={(e) => {
               clearError("guarantor_options");
               handleChange(e.target.value, "guarantor_options");
             }}
             onWheel={(e) => e.target.blur()}
           />
             {error?.guarantor_options && <p className="body-S red-D">{error.guarantor_options}</p>}
         </div>
        </div>


      <div className="mt-6">
        <button type="submit" className="save-button light-L head-5 green-bg-H">
          Save Property
        </button>

        <button type="button" onClick={handleClose} className="green-H ml-5">
          Cancel
        </button>
      </div>
    </form>
  );
};

export default AddPropertyModalData;
