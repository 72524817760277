import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { NotificationManager } from "react-notifications";
import axios from "axios";
import useAuth from "../../hooks/useAuth";
import Loader from "../../components/Loader";
import { BASE_URL } from "../../utils/Element";
import BaseLayout from "../../layouts/BaseLayout";
import LeftIcon from "../../assets/svgs/leftArrowIcon.svg";
import CompanyUpdateTab from "./components/CompanyUpdateTab";
import RightIcon from "../../assets/svgs/rightArrowIcon.svg";
import TaskTab from "../../components/DetailTabsData/TaskTab";
import CompanyContactTab from "./components/CompanyContactTab";
import CompanyDetailHeader from "./components/CompanyDetailHeader";
import SearchDropdownList from "../../components/SearchDropdownList";
import PropertyTab from "../../components/DetailTabsData/PropertyTab";
import SmartPlanTab from "../../components/DetailTabsData/SmartPlanTab";
import AcquisitionTab from "../../components/DetailTabsData/AcquisitionTab";
import { initialCompanyFilterData } from "../../utils/initialData";

const CompanyDetails = (filterState ) => {
  const { id } = useParams();
  const [config] = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const [loading, setLoading] = useState(true);
  const [activeTab, setActiveTab] = useState("updates");
  const [companyDetails, setCompanyDetails] = useState();
  const handleNavigation = (companyId) => {
    navigate(`/company/${companyId}?filter=${encodeURIComponent(JSON.stringify(filterState))}`);
  };


  const fetchCompanyDetails = () => {
    setLoading(true);
    const savedQueryParams = localStorage.getItem("queryParams");
    let localQueryParams = {};
  
    if (savedQueryParams) {
      localQueryParams = Object.fromEntries(new URLSearchParams(savedQueryParams));
    }
    
   axios
      .post(`${BASE_URL}/company-getbyid/${id}`,localQueryParams, config)
      .then((res) => {
        setCompanyDetails(res?.data?.data);
      })
      .catch((err) => {
        if (err.response?.data?.message && err.response?.data?.message !== "Too Many Attempts.") {
          NotificationManager.error(err.response?.data?.message);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchCompanyDetails();
  }, [id]);

  const queryParams = new URLSearchParams(location.search);
  const searchValue = queryParams.get('search');

  return (
    <BaseLayout>
      <div className="flex justify-between items-center">
        <p role="button" className="green-H head-5 underline"  onClick={() => navigate(`/companies?search=${searchValue || ''}`)}>
          Back to Companies                                  
        </p>

        <div className="body-S dark-M flex items-center gap-5">
           {companyDetails?.previous_id !== null && (
             <div
               role="button"
               onClick={() => handleNavigation(companyDetails.previous_id)}
               className="flex items-center gap-2"
             >
               <img src={LeftIcon} alt="Previous" />
               Previous Company
             </div>
          )}

          {companyDetails?.next_id !== null && (
           <div
             role="button"
             onClick={() => handleNavigation(companyDetails.next_id)}
             className="flex items-center gap-2"
            >
             Next Company
             <img src={RightIcon} alt="Next" />
            </div>
             )}
           </div>

        <SearchDropdownList from="company" />
      </div>

      {loading ? (
        <Loader />
      ) : (
        <>
          <CompanyDetailHeader companyDetails={companyDetails} onSetCallApiAgain={fetchCompanyDetails} />

          <div className="contact-details light-bg-L" style={{ paddingLeft: "0px", paddingRight: "0px" }}>
            <div className="flex justify-between header-tabs">
              <p role="button" onClick={() => setActiveTab("updates")} className={`${activeTab === "updates" ? "head-5 dark-H active" : "body-S dark-M "} tab`}>
                Updates
              </p>
              <p role="button" onClick={() => setActiveTab("contacts")} className={`${activeTab === "contacts" ? "head-5 dark-H active" : "body-S dark-M"} tab`}>
                Contacts
              </p>
              <p role="button" onClick={() => setActiveTab("properties")} className={`${activeTab === "properties" ? "head-5 dark-H active" : "body-S dark-M"} tab`}>
                Properties
              </p>
              <p role="button" onClick={() => setActiveTab("acquisition")} className={`${activeTab === "acquisition" ? "head-5 dark-H active" : "body-S dark-M"} tab`}>
                Acquisition Criteria
              </p>
              <p role="button" onClick={() => setActiveTab("tasks")} className={`${activeTab === "tasks" ? "head-5 dark-H active" : "body-S dark-M"} tab`}>
                Tasks
              </p>
              <p role="button" onClick={() => setActiveTab("smartPlans")} className={`${activeTab === "smartPlans" ? "head-5 dark-H active" : "body-S dark-M"} tab`}>
                TouchPlans
              </p>
            </div>

            <div className="p-4">
              {activeTab === "updates" && <CompanyUpdateTab />}
              {activeTab === "contacts" && <CompanyContactTab />}
              {activeTab === "properties" && <PropertyTab from="company" />}
              {activeTab === "acquisition" && <AcquisitionTab from="company" />}
              {activeTab === "tasks" && <TaskTab from="company" />}
              {activeTab === "smartPlans" && <SmartPlanTab from="company" />}
            </div>
          </div>
        </>
      )}
    </BaseLayout>
  );
};

export default CompanyDetails;
